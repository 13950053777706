import React, { useEffect, useState, Fragment, useRef } from "react";
import "./Maintenance.css";
import Loader from "../loader/Loader";
import axios from "../axios";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { RxTriangleRight } from "react-icons/rx";
import { GiCheckMark } from "react-icons/gi";
import { IoStar } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MaintenanceHeader from "../components/MaintenanceHeader";
import MaintenanceBg from "../Assets/maintenance/MaintenanceBg-dark.svg";
import Vector1 from "../Assets/maintenance/Vector1.png";
import Vector4 from "../Assets/maintenance/Vector4.png";
import Vector3 from "../Assets/maintenance/Vector3.png";
import Vector2 from "../Assets/maintenance/Vector2.png";
import Vector6 from "../Assets/maintenance/Vector6.png";
import MaintenanceBgDark from "../Assets/maintenance/MaintenanceBg-light.svg";
import { motion, transform } from "framer-motion";
import Lottie from "lottie-react";
import MaintenanceAni from "../jsondata/MaintenanceHeaderAni.json";

const MaintenanceH1Variants = {
  hidden: {
    y: -20,
    x: -5,
    scaleX: 0.5,
  },
  visible: {
    y: 0,
    x: 0,
    scaleX: 1,
  },
};
export default function Maintenance() {
  const fitHeading = "Maintenance";
  const [homebgChange, setHomebgChange] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  });
  return !loader ? (
    <Loader />
  ) : (
    <Fragment>
      <MaintenanceHeader
        homebgChange={homebgChange}
        setHomebgChange={setHomebgChange}
      />
      <section className="Maintenance" id="Maintenance-Home">
        <img
          src={homebgChange ? MaintenanceBg : MaintenanceBgDark}
          className="MaintenanceHeaderBg"
        />

        <div className="Maintenance-heading ">
          <div className="Maintenance-heading-animation">
            <Lottie animationData={MaintenanceAni} loop={true} />
          </div>
          {fitHeading.split("").map((heading, index) => {
            return heading === " " ? (
              <h1 key={index}>&nbsp;</h1>
            ) : (
              <motion.h1
                variants={MaintenanceH1Variants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: "spring",
                  duration: 2,
                  delay: index * 0.05,
                  stiffness: 300,
                }}
                key={index}
              >
                {heading}
              </motion.h1>
            );
          })}
        </div>
      </section>
      <MaintenanceForm />
    </Fragment>
  );
}

const vector1Variants = {
  hidden: {
    transform: "translateX(100px)",
  },
  visible: {
    transform: "translateX(0px)",
    transform: "rotateZ(-30deg)",
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
};
const vector4Variants = {
  hidden: {
    transform: "translateX(-100px)",
  },
  visible: {
    transform: "translateX(0px)",
    transform: "rotateZ(25deg)",
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
};
const vector2Variants = {
  hidden: {
    transform: "translateY(80px)",
  },
  visible: {
    transform: "translateY(0px)",
    transition: {
      type: "spring",
      stiffness: 200,
    },
  },
};
function MaintenanceForm() {
  const [faultAreaRecId, setFaultAreaRecId] = useState(null);
  const [faultTypeRecId, setFaultTypeRecId] = useState(null);
  const [faultSymptomRecId, setFaultSymptomRecId] = useState(null);
  const [assetType, setAssetType] = useState(null);
  const [showDropdownRequestType, setShowDropdownRequestType] = useState(false);
  const [showDropdownFunctionalLocation, setshowDropdownFunctionalLocation] =
    useState(false);
  const [showDropdownAsset, setshowDropdownAsset] = useState(false);
  const [showDropdownServiceLevel, setShowDropdownServiceLevel] =
    useState(false);
  const [showDropdownFaultSymptom, setShowDropdownFaultSymptom] =
    useState(false);
  const [showDropdownFaultArea, setShowDropdownFaultArea] = useState(false);
  const [showDropdownFaultType, setShowDropdownFaultType] = useState(false);
  const [maintenanceRequestType, setMaintenanceRequestType] = useState(null);
  const [functionalLocation, setFunctionalLocation] = useState(null);
  const [asset, setAsset] = useState(null);
  const [serviceLevel, setServiceLevel] = useState(null);
  const [faultSymptom, setFaultSymptom] = useState(null);
  const [faultArea, setFaultArea] = useState(null);
  const [faultType, setFaultType] = useState(null);
  const [error, setError] = useState(null);
  const [fitProspectData, setFitProspectData] = useState(null);

  const [inputValue, setInputValue] = useState({
    RequestType: "",
    Description: "",
    FunctionalLocation: "",
    Asset: "",
    ServiceLevel: "",
    FaultSymptom: "",
    FaultArea: "",
    FaultType: "",
    MaintenanceDowntime: "",
    ActualStart: "",
    Notes: "",
  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get("/api/data/all");
        setMaintenanceRequestType(response.data.maintenanceRequestType.value);
        setFunctionalLocation(response.data.functionalLocation.value);
        setAsset(response.data.asset.value);
        setServiceLevel(response.data.serviceLevel.value);
        setFaultSymptom(response.data.faultSymptom.value);
        setFaultArea(response.data.faultArea.value);
        setFaultType(response.data.faultType.value);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAllData();
  }, []);
  function inputValueHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [name]: name === "MaintenanceDowntime" ? `${value}:00Z` : value,
    }));
  }

  function addValueToInputRequestType(requestTypeId) {
    setInputValue({ ...inputValue, RequestType: requestTypeId });
    setShowDropdownRequestType(false);
  }
  function addValueToInputFunctionalLocation(functionalLocationId) {
    setInputValue({ ...inputValue, FunctionalLocation: functionalLocationId });
    setshowDropdownFunctionalLocation(false);
  }
  function addValueToInputAsset(assetId, assetTypeId) {
    setInputValue({ ...inputValue, Asset: assetId });
    setAssetType(assetTypeId);
    setshowDropdownAsset(false);
  }
  function addValueToInputServiceLevel(serviceLevel) {
    setInputValue({ ...inputValue, ServiceLevel: serviceLevel });
    setShowDropdownServiceLevel(false);
  }
  function addValueToInputFaultSymptom(FaultSymptomId, FaultSymptomrecid) {
    setInputValue({ ...inputValue, FaultSymptom: FaultSymptomId });
    setFaultSymptomRecId(FaultSymptomrecid);
    setShowDropdownFaultSymptom(false);
  }
  function addValueToInputFaultArea(FaultAreaId, FaultArearecid) {
    setInputValue({ ...inputValue, FaultArea: FaultAreaId });
    setFaultAreaRecId(FaultArearecid);
    setShowDropdownFaultArea(false);
  }
  function addValueToInputFaultType(FaultTypeId, FaultTyperecid) {
    setInputValue({ ...inputValue, FaultType: FaultTypeId });
    setFaultTypeRecId(FaultTyperecid);
    setShowDropdownFaultType(false);
  }
  function showDropdownRequestTypeHandler() {
    setShowDropdownRequestType(true);
  }
  function showDropdownRequestTypeBlurHandler() {
    setTimeout(() => {
      setShowDropdownRequestType(false);
    }, 150);
  }
  function showDropdownFunctionalLocationHandler() {
    setshowDropdownFunctionalLocation(true);
  }
  function showDropdownFunctionalLocationBlurHandler() {
    setTimeout(() => {
      setshowDropdownFunctionalLocation(false);
    }, 150);
  }
  function showDropdownAssetHandler() {
    setshowDropdownAsset(true);
  }
  function showDropdownAssetBlurHandler() {
    setTimeout(() => {
      setshowDropdownAsset(false);
    }, 150);
  }
  function showDropdownServiceLevelHandler() {
    setShowDropdownServiceLevel(true);
  }
  function showDropdownServiceLevelBlurHandler() {
    setTimeout(() => {
      setShowDropdownServiceLevel(false);
    }, 150);
  }
  function showDropdownFaultSymptomHandler() {
    setShowDropdownFaultSymptom(true);
  }
  function showDropdownFaultSymptomBlurHandler() {
    setTimeout(() => {
      setShowDropdownFaultSymptom(false);
    }, 150);
  }
  function showDropdownFaultAreaHandler() {
    setShowDropdownFaultArea(true);
  }
  function showDropdownFaultAreaBlurHandler() {
    setTimeout(() => {
      setShowDropdownFaultArea(false);
    }, 150);
  }
  function showDropdownFaultTypeHandler() {
    setShowDropdownFaultType(true);
  }
  function showDropdownFaultTypeBlurHandler() {
    setTimeout(() => {
      setShowDropdownFaultType(false);
    }, 150);
  }
  function showDropdownRemoveHandler() {
    setShowDropdownRequestType(false);
  }
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const DateTime = currentDate.toISOString().slice(0, 19) + "Z";
    return DateTime;
  };
  useEffect(() => {
    setInputValue((prev) => ({
      ...prev,
      ActualStart: getCurrentDateTime(),
    }));
  }, []);

  useEffect(() => {
    if (fitProspectData) {
      const sendProspectData = async () => {
        try {
          const response = await axios.post(
            "/api/maintenance-prospects",
            fitProspectData
          );

          toast.success(
            `${response.data ? response.data : ""} Sent successfully`,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        } catch (error) {
          console.error(
            "Error:",
            error.response?.data || error.message || error
          );
          console.log("An error occurred.");
          toast.error("An error occurred while uploading files.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } finally {
          setInputValue({
            RequestType: "",
            Description: "",
            FunctionalLocation: "",
            Asset: "",
            ServiceLevel: "",
            FaultSymptom: "",
            FaultArea: "",
            FaultType: "",
            MaintenanceDowntime: "",
            ActualStart: "",
            Notes: "",
          });
          setFaultSymptomRecId(null);
          setFaultAreaRecId(null);
          setFaultTypeRecId(null);
          setFitProspectData(null);
        }
      };

      sendProspectData();
    }
  }, [fitProspectData]);

  async function formSubmitHandler(e) {
    e.preventDefault();
    if (
      !inputValue.RequestType.trim() ||
      !inputValue.FunctionalLocation.trim() ||
      !inputValue.Description.trim() ||
      !inputValue.Asset.trim() ||
      !inputValue.ServiceLevel.toString().trim() ||
      !inputValue.FaultSymptom.trim() ||
      !inputValue.FaultArea.trim() ||
      !inputValue.FaultType.trim() ||
      !inputValue.ActualStart.trim() ||
      !inputValue.Notes.trim()
    ) {
      toast.warn("Please Enter Your Details!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      try {
        const newProspectData = {
          requesttype: inputValue.RequestType,
          description: inputValue.Description,
          FunctionalLocation: inputValue.FunctionalLocation,
          asset: inputValue.Asset,
          servicelevel: inputValue.ServiceLevel,
          faultsymptom: faultSymptomRecId,
          faultarea: faultAreaRecId,
          faulttype: faultTypeRecId,
          maintenancedowntime:
            inputValue.MaintenanceDowntime || "1990-01-01T01:01:00Z",
          Actualstart: inputValue.ActualStart,
          company: "USMF",
          notes: inputValue.Notes,
        };
        setFitProspectData(newProspectData);
        console.log(newProspectData);
      } catch (error) {
        console.error("Error setting prospect data:", error);
        toast.error("Failed to prepare prospect data.");
      }
      e.target.reset();
    }
  }
  return (
    <Fragment>
      <section className="Maintenance-Form-Section" id="Maintenance">
        <motion.img
          src={Vector1}
          alt=""
          className="form-vector1"
          variants={vector1Variants}
          initial="hidden"
          whileInView="visible"
        />
        <motion.img
          src={Vector3}
          alt=""
          className="form-vector2"
          variants={vector2Variants}
          initial="hidden"
          whileInView="visible"
        />
        <img src={Vector2} alt="" className="form-vector3" />
        <motion.img
          src={Vector4}
          alt=""
          className="form-vector4"
          variants={vector4Variants}
          initial="hidden"
          whileInView="visible"
        />
        <img src={Vector6} alt="" className="form-vector6" />
        <div className="container Maintenance-Form-Container">
          <form onSubmit={formSubmitHandler}>
            <div className="Maintenance-RequestType form-floating">
              <input
                type="text"
                value={inputValue.RequestType}
                className="form-control"
                name="RequestType"
                id="requestType"
                onClick={showDropdownRequestTypeHandler}
                onBlur={showDropdownRequestTypeBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="requestType">Maintenance-Request Type</label>
              {showDropdownRequestType && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Maintenance Request Type</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {maintenanceRequestType &&
                        maintenanceRequestType
                          .filter((requestType) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              inputValue.RequestType.trim();

                            if (inputValueTrimmed === "") {
                              return requestType;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              return (
                                requestType.RequestTypeId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                requestType.Name.toUpperCase().includes(
                                  upperInput
                                )
                              );
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              return (
                                requestType.RequestTypeId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                requestType.Name.toLowerCase().includes(
                                  lowerInput
                                )
                              );
                            }
                          })
                          .map((requestType, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputRequestType(
                                  requestType.RequestTypeId
                                )
                              }
                              key={requestType["@odata.etag"] || index}
                            >
                              <td>{requestType.RequestTypeId}</td>
                              <td>{requestType.Name}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-Description form-floating">
              <input
                type="text"
                value={inputValue.Description}
                className="form-control"
                name="Description"
                id="description"
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="description">Description</label>
            </div>
            <div className="Maintenance-FunctionalLocation form-floating">
              <input
                type="text"
                value={inputValue.FunctionalLocation}
                className="form-control"
                name="FunctionalLocation"
                id="functional-location"
                onClick={showDropdownFunctionalLocationHandler}
                onBlur={showDropdownFunctionalLocationBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="functional-location">Functional Location</label>
              {showDropdownFunctionalLocation && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Functional Location</th>
                        <th>Name</th>
                        <th>Functional Location Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {functionalLocation &&
                        functionalLocation
                          .filter((functional) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              inputValue.FunctionalLocation.trim();

                            if (inputValueTrimmed === "") {
                              return functional;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              return (
                                functional.FunctionalLocationId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                functional.Name.toUpperCase().includes(
                                  upperInput
                                ) ||
                                functional.FunctionalLocationTypeId.toUpperCase().includes(
                                  upperInput
                                )
                              );
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              return (
                                functional.FunctionalLocationId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                functional.Name.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                functional.FunctionalLocationTypeId.toLowerCase().includes(
                                  lowerInput
                                )
                              );
                            }
                          })
                          .map((functional, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputFunctionalLocation(
                                  functional.FunctionalLocationId
                                )
                              }
                              key={functional["@odata.etag"] || index}
                            >
                              <td>{functional.FunctionalLocationId}</td>
                              <td>{functional.Name}</td>
                              <td>{functional.FunctionalLocationTypeId}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-Asset form-floating">
              <input
                type="text"
                value={inputValue.Asset}
                className="form-control"
                name="Asset"
                id="maintenance-asset"
                onClick={showDropdownAssetHandler}
                onBlur={showDropdownAssetBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="maintenance-asset">Asset</label>
              {showDropdownAsset && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Asset</th>
                        <th>Name</th>
                        <th>Asset Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {asset &&
                        asset
                          .filter((asse) => {
                            const caseSensitive = true;
                            const inputValueTrimmed = inputValue.Asset.trim();
                            const functionalLocationMatch =
                              asse.FunctionalLocationId ===
                              inputValue.FunctionalLocation;

                            if (inputValueTrimmed === "") {
                              return functionalLocationMatch;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              const assetMatch =
                                asse.MaintenanceAssetId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                asse.Name.toUpperCase().includes(upperInput) ||
                                asse.MaintenanceAssetTypeId.toUpperCase().includes(
                                  upperInput
                                );
                              return functionalLocationMatch && assetMatch;
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              const assetMatch =
                                asse.MaintenanceAssetId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                asse.Name.toLowerCase().includes(lowerInput) ||
                                asse.MaintenanceAssetTypeId.toLowerCase().includes(
                                  lowerInput
                                );

                              return functionalLocationMatch && assetMatch;
                            }
                          })
                          .map((asse, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputAsset(
                                  asse.MaintenanceAssetId,
                                  asse.MaintenanceAssetTypeId
                                )
                              }
                              key={asse["@odata.etag"] || index}
                            >
                              <td>{asse.MaintenanceAssetId}</td>
                              <td>{asse.Name}</td>
                              <td>{asse.MaintenanceAssetTypeId}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-ServiceLevel form-floating">
              <input
                type="text"
                value={inputValue.ServiceLevel}
                className="form-control"
                name="ServiceLevel"
                id="service-level"
                onClick={showDropdownServiceLevelHandler}
                onBlur={showDropdownServiceLevelBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="service-level">Service Level</label>
              {showDropdownServiceLevel && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Service Level</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceLevel &&
                        serviceLevel
                          .filter((service) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              typeof inputValue.ServiceLevel === "string"
                                ? inputValue.ServiceLevel.trim()
                                : inputValue.ServiceLevel.toString().trim();

                            const serviceLevelValue =
                              typeof service.ServiceLevel === "string" ||
                              typeof service.ServiceLevel === "number"
                                ? service.ServiceLevel.toString()
                                : "";

                            const serviceNameValue =
                              typeof service.Name === "string" ||
                              typeof service.Name === "number"
                                ? service.Name.toString()
                                : "";

                            if (inputValueTrimmed === "") {
                              return true; // Return all when input is empty
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              return (
                                serviceLevelValue
                                  .toUpperCase()
                                  .includes(upperInput) ||
                                serviceNameValue
                                  .toUpperCase()
                                  .includes(upperInput)
                              );
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              return (
                                serviceLevelValue
                                  .toLowerCase()
                                  .includes(lowerInput) ||
                                serviceNameValue
                                  .toLowerCase()
                                  .includes(lowerInput)
                              );
                            }
                          })
                          .map((service, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputServiceLevel(
                                  service.ServiceLevel
                                )
                              }
                              key={service["@odata.etag"] || index}
                            >
                              <td>{service.ServiceLevel}</td>
                              <td>{service.Name}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-FaultSymptom form-floating">
              <input
                type="text"
                value={inputValue.FaultSymptom}
                className="form-control"
                name="FaultSymptom"
                id="fault-symptom"
                onClick={showDropdownFaultSymptomHandler}
                onBlur={showDropdownFaultSymptomBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
                disabled={!inputValue.Asset}
              />
              <label htmlFor="fault-symptom">Fault Symptom</label>
              {showDropdownFaultSymptom && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Fault Symptom</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {faultSymptom &&
                        faultSymptom
                          .filter((fault) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              inputValue.FaultSymptom.trim();
                            const assetMatch = fault.ObjectType === assetType;

                            if (inputValueTrimmed === "") {
                              return assetMatch;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              const faultMatch =
                                fault.FaultSymptomId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                fault.Description.toUpperCase().includes(
                                  upperInput
                                );
                              return assetMatch && faultMatch;
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              const faultMatch =
                                fault.FaultSymptomId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                fault.Description.toLowerCase().includes(
                                  lowerInput
                                );
                              return assetMatch && faultMatch;
                            }
                          })
                          .map((fault, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputFaultSymptom(
                                  fault.FaultSymptomId,
                                  fault.FaultSymptomrecid
                                )
                              }
                              key={fault["@odata.etag"] || index}
                            >
                              <td>{fault.FaultSymptomId}</td>
                              <td>{fault.Description}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-FaultArea form-floating">
              <input
                type="text"
                value={inputValue.FaultArea}
                className="form-control"
                name="FaultArea"
                id="fault-area"
                onClick={showDropdownFaultAreaHandler}
                onBlur={showDropdownFaultAreaBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
                disabled={!inputValue.FaultSymptom}
              />
              <label htmlFor="fault-area">Fault Area</label>
              {showDropdownFaultArea && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Fault Area</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {faultArea &&
                        faultArea
                          .filter((fault) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              inputValue.FaultArea.trim();
                            const assetMatch = fault.ObjectType === assetType;

                            if (inputValueTrimmed === "") {
                              return assetMatch;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              const faultMatch =
                                fault.FaultAreaId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                fault.Description.toUpperCase().includes(
                                  upperInput
                                );
                              return assetMatch && faultMatch;
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              const faultMatch =
                                fault.FaultAreaId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                fault.Description.toLowerCase().includes(
                                  lowerInput
                                );
                              return assetMatch && faultMatch;
                            }
                          })
                          .map((fault, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputFaultArea(
                                  fault.FaultAreaId,
                                  fault.FaultArearecid
                                )
                              }
                              key={fault["@odata.etag"] || index}
                            >
                              <td>{fault.FaultAreaId}</td>
                              <td>{fault.Description}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-FaultType form-floating">
              <input
                type="text"
                value={inputValue.FaultType}
                className="form-control"
                name="FaultType"
                id="fault-type"
                onClick={showDropdownFaultTypeHandler}
                onBlur={showDropdownFaultTypeBlurHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
                disabled={!inputValue.FaultSymptom}
              />
              <label htmlFor="fault-type">Fault Type</label>
              {showDropdownFaultType && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Fault Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {faultType &&
                        faultType
                          .filter((fault) => {
                            const caseSensitive = true;
                            const inputValueTrimmed =
                              inputValue.FaultType.trim();
                            const assetMatch = fault.ObjectType === assetType;

                            if (inputValueTrimmed === "") {
                              return assetMatch;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              const faultMatch =
                                fault.FaultTypeId.toUpperCase().includes(
                                  upperInput
                                ) ||
                                fault.Description.toUpperCase().includes(
                                  upperInput
                                );
                              return assetMatch && faultMatch;
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              const faultMatch =
                                fault.FaultTypeId.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                fault.Description.toLowerCase().includes(
                                  lowerInput
                                );
                              return assetMatch && faultMatch;
                            }
                          })
                          .map((fault, index) => (
                            <tr
                              onClick={() =>
                                addValueToInputFaultType(
                                  fault.FaultTypeId,
                                  fault.FaultTypeRecId
                                )
                              }
                              key={fault["@odata.etag"] || index}
                            >
                              <td>{fault.FaultTypeId}</td>
                              <td>{fault.Description}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Maintenance-Downtime form-floating">
              <input
                type="datetime-local"
                value={inputValue.MaintenanceDowntime.slice(0, 16)}
                className="form-control"
                name="MaintenanceDowntime"
                id="maintenance-downtime"
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="maintenance-downtime">Maintenance Downtime</label>
            </div>
            <div className="Maintenance-ActualStart form-floating">
              <input
                type="text"
                value={inputValue.ActualStart}
                className="form-control"
                name="ActualStart"
                id="actual-start"
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="actual-start">Actual Start</label>
            </div>
            <div className="Maintenance-Notes form-floating">
              <input
                type="text"
                value={inputValue.Notes}
                className="form-control"
                name="Notes"
                id="notes"
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
                placeholder="MaintenanceRequestType"
              />
              <label htmlFor="notes">Notes</label>
            </div>
            <div className="formMaintenance-button-group">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundImage:
                    "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #9098e8, #8396ee, #7394f5, #7182fb, #7a6dfd, #8b51fa, #a020f0);",
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
                  color: "#2b2c2c",
                  fontWeight: "bold",
                }}
                size="large"
                className="formMaintenance-submit-button"
              >
                Send
              </Button>
            </div>
            {/* <div className="d-flex show-file-name">
              <div>
                {selectedRequestDocIds &&
                  selectedRequestDocIds.map((docIds, index) => {
                    return (
                      <div className="selectedRequestDocIds-h6" key={docIds}>
                        <h6 className="selectedRequestDocIds-h6-h6">
                          <IoStar className="upload-files-show-star-h6" />
                          {docIds}
                        </h6>
                      </div>
                    );
                  })}
              </div>
              <div>
                {files &&
                  files.map((file, index) => {
                    const baseFileName = file.fileName
                      .split(".")
                      .slice(0, -1)
                      .join(".");
                    return (
                      <div
                        key={file.fileName}
                        className="upload-files-show-name"
                      >
                        <p className="upload-files-show-name-p">
                          <FaLongArrowAltRight className="upload-files-arrow" />
                          <IoStar className="upload-files-show-star" />
                          {baseFileName}
                          <MdCancel
                            className="upload-files-cancel"
                            onClick={() => removePhotoHandler(index)}
                          />
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div> */}
          </form>
        </div>
      </section>
    </Fragment>
  );
}
