import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  Suspense,
} from "react";
import "./Event.css";
import EventHeader from "../components/EventHeader";
import { motion } from "framer-motion";
import "react-phone-number-input/style.css";
import Loader from "../loader/Loader";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Balloon2 from "../Assets/event/balloon/balloon2.png";
import Balloon3 from "../Assets/event/balloon/balloon3.png";
import Balloon4 from "../Assets/event/balloon/balloon4.png";
import EventBgDark from "../Assets/event/EventBg-Dark.svg";
import EventBgLight from "../Assets/event/EventBg-Light.svg";
import { toast } from "react-toastify";
import axios from "../axios";
import { IoMdArrowDropright } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import Lottie from "lottie-react";
import AnimationBlob2 from "../jsondata/EventHeaderAni2.json";
import WindMillAni from "../jsondata/EventWindMillAni2.json";
import WindMillAni2 from "../jsondata/EventWindMillAni.json";
import CrackersAni from "../jsondata/EventCrackersAni.json";
import CrackersAni2 from "../jsondata/EventCrackersAni2.json";
import Star1 from "../Assets/event/stars/star1.png";
import Star2 from "../Assets/event/stars/star2.png";
import Star3 from "../Assets/event/stars/star3.png";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const EventParticles = React.lazy(() => import("../components/EventParticles"));

const headingVariants = {
  hidden: {
    y: -20,
    x: -5,
    scaleX: 0.5,
  },
  visible: {
    y: 0,
    x: 0,
    scaleX: 1,
  },
};
const MAX_FILE_SIZE = 10 * 1024 * 1024;

export default function Event() {
  const homeHeading = " Permit";
  const homeHeading2 = "Request";
  const [homebgChange, setHomebgChange] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const memoizedHeading1 = useMemo(() => {
    return homeHeading.split("").map((text, index) => (
      <motion.h1
        key={index}
        className="Event-card-body-h1"
        variants={headingVariants}
        initial="hidden"
        animate="visible"
        transition={{
          type: "spring",
          duration: 2,
          delay: index * 0.05,
          stiffness: 300,
        }}
      >
        {text === " " ? "\u00A0\u00A0" : text}
      </motion.h1>
    ));
  }, [homeHeading]);

  const memoizedHeading2 = useMemo(() => {
    return homeHeading2.split("").map((text, index) =>
      text === " " ? (
        <h1 key={index}>&nbsp;&nbsp;</h1>
      ) : (
        <motion.h1
          className="Event-card-body-h1"
          key={index}
          variants={headingVariants}
          initial="hidden"
          animate="visible"
          transition={{
            type: "spring",
            duration: 2,
            delay: index * 0.05,
            stiffness: 300,
          }}
        >
          {text}
        </motion.h1>
      )
    );
  }, [homeHeading2]);

  return !loader ? (
    <Loader />
  ) : (
    <Fragment>
      <EventHeader
        homebgChange={homebgChange}
        setHomebgChange={setHomebgChange}
      />
      <main className="Event" id="Event-Home">
        <img
          src={homebgChange ? EventBgDark : EventBgLight}
          className="Event-bg"
        />
        <div className="Event-body">
          <Suspense fallback={<div>Loading Particles...</div>}>
            <EventParticles />
          </Suspense>
          <div className="d-flex gap-2">
            {memoizedHeading1}
            <div className="Event-HeadingAnimation">
              <Lottie animationData={AnimationBlob2} loop={true} />
            </div>
            {memoizedHeading2}
          </div>
        </div>
      </main>
      <EventForm homebgChange={homebgChange} />
    </Fragment>
  );
}
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#a020f0",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

function EventForm({ homebgChange }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [value, setValue] = useState();
  const [image, setImage] = useState("");
  const [files, setFiles] = useState([]);
  const [filenName, setFilesName] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [businesses, setBusinesses] = useState(null);
  const [unit, setUnit] = useState([]);
  const [prospectData, setProspectData] = useState(null);
  const [busRelAccount, setBusRelAccount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [permit, setPermit] = useState("");
  const [permitAbove, setPermitAbove] = useState("");
  const [specialRequestLabel, setSpecialRequestLabel] =
    useState("Special Request");

  function handlePermitChange(e) {
    const value = e.target.value;
    setPermit(value);
    if (value === "Event") {
      // setInputValue((prevValue) => ({
      //   ...prevValue,
      //   SpecialRequest: "Event : ",
      // }));
      setSpecialRequestLabel("Special Event Request");
      setPermitAbove("Event - ");
    } else if (value === "Media") {
      // setInputValue((prevValue) => ({
      //   ...prevValue,
      //   SpecialRequest: "Media : ",
      // }));
      setSpecialRequestLabel("Special Media Request");
      setPermitAbove("Media - ");
    }
  }

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get("/api/data/all");
        // https://app-qdk5soz5sq-uc.a.run.app
        // Set the data for both SuperProperties and Businesses
        setData(response.data.superProperties.value);
        setBusinesses(response.data.businesses.value);
        setUnit(response.data.unitsOfMeasure.value);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAllData();
  }, []);
  const [inputValue, setInputValue] = useState({
    Name: "",
    LineOfBusiness: "1000",
    Telephone: "",
    FaxNo: "",
    PhoneNumber: "",
    Email: "",
    InterestedIn: "FL",
    PropertyType: "Retail",
    BrandName: "",
    Unit: "SQ.M",
    RequiredArea: "",
    SpecialRequest: "",
    PermitDate: "",
    Notes: "",
    CivilId: "",
  });

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newFiles = await Promise.all(
      selectedFiles.map(async (file) => {
        const base64 = await convertBase64(file);
        setFilesName(file);
        return { fileName: file.name, base64 };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    event.target.value = null;
  };
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result.split(",")[1];
        resolve(base64String);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    if (prospectData) {
      const sendProspectData = async () => {
        setIsSubmitting(true);
        try {
          const response = await axios.post(
            "/api/event-prospects",
            // https://app-qdk5soz5sq-uc.a.run.app/api/data/all
            prospectData
          );

          toast.success(
            `${response.data ? response.data : null} Sent successfully`,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );

          setBusRelAccount(response.data);
          await uploadFiles(response.data);
        } catch (error) {
          console.error(
            "Error:",
            error.response?.data || error.message || error
          );
          console.log("An error occurred.");
          toast.error("An error occurred while uploading files.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } finally {
          setProspectData(null);
          setFiles([]);
          setSpecialRequestLabel("Special Request");
          setPermitAbove("");
          setPermit("");
          setIsSubmitting(false);
          prevFilesLength.current = 0;
        }
      };

      sendProspectData();
    }
  }, [prospectData, files]);

  const uploadFiles = async (busRelAccount) => {
    try {
      const filesData = files.map((file) => {
        const baseFileName = file.fileName.split(".").slice(0, -1).join(".");

        return {
          FileName: baseFileName,
          dataAreaId: "USMF",
          DocumentAttachmentTypeCode: "File",
          AttachmentDescription: baseFileName,
          FileType: file.fileName.split(".").pop(),
          BusRelAccount: busRelAccount,
          Notes: "",
          Attachment: file.base64,
        };
      });

      for (const fileData of filesData) {
        await axios.post("/api/upload-files", fileData);

        toast.success(`${fileData.FileName} Sent successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message || error
      );
      toast.error("An error occurred while uploading files.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const removePhotoHandler = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const prevFilesLength = useRef(files.length);
  useEffect(() => {
    if (!isSubmitting) {
      if (files.length > prevFilesLength.current) {
        toast.success("Uploaded Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (files.length < prevFilesLength.current) {
        toast.success("Removed Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    prevFilesLength.current = files.length;
  }, [files, isSubmitting]);
  function inputValueHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (permit.trim()) {
      setInputValue({ ...inputValue, [name]: value });
    } else {
      toast.warn("Please Select the Permit Above!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  function showDropdownHandler() {
    setShowDropdown(!showDropdown);
    setShowDropdown2(false);
  }
  function showDropdownBlurHandler() {
    setTimeout(() => {
      setShowDropdown(false);
    }, 150);
  }
  function showDropdownHandler2() {
    setShowDropdown2(!showDropdown2);
    setShowDropdown(false);
  }
  function showDropdownBlurHandler2() {
    setTimeout(() => {
      setShowDropdown2(false);
    }, 150);
  }
  function showDropdownRemoveHandler() {
    setShowDropdown(false);
    setShowDropdown2(false);
  }
  function addValueToInput1(business) {
    setInputValue({ ...inputValue, LineOfBusiness: business });
    setShowDropdown(false);
  }
  function addValueToInput2(unit) {
    setInputValue({ ...inputValue, Unit: unit });
    setShowDropdown2(false);
  }
  async function formSubmitHandler(e) {
    e.preventDefault();
    if (!permit) {
      toast.warn("Please Select the Permit Above!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (
      !inputValue.Name?.trim() ||
      !inputValue.LineOfBusiness?.trim() ||
      !inputValue.Email?.trim() ||
      !inputValue.Unit?.trim() ||
      !inputValue.InterestedIn?.trim() ||
      !inputValue.PropertyType?.trim() ||
      !inputValue.RequiredArea?.trim() ||
      !inputValue.Notes?.trim() ||
      !inputValue.BrandName?.trim() ||
      !inputValue.CivilId?.trim() ||
      !inputValue.PermitDate?.trim()
    ) {
      toast.warn("Please Enter Your Details!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (files.length === 0) {
      toast.warn("Please Upload Your File!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      try {
        const newProspectData = {
          name: inputValue.Name,
          superproperty: inputValue.InterestedIn,
          brandName: inputValue.BrandName,
          LineofBusiness: inputValue.LineOfBusiness,
          area: inputValue.RequiredArea,
          Unit: inputValue.Unit,
          reasonrequest: inputValue.Notes,
          Specialrequests: permitAbove + inputValue.SpecialRequest,
          CivilIDnumber: inputValue.CivilId,
          Permitdate: inputValue.PermitDate,
          propertid: inputValue.PropertyType,
          Phone: inputValue.PhoneNumber,
          email: inputValue.Email,
          Fax: inputValue.FaxNo,
          Company: "USMF",
          country: "USA",
        };
        setProspectData(newProspectData);
      } catch (error) {
        console.error("Error setting prospect data:", error);
        toast.error("Failed to prepare prospect data.");
      }
      e.target.reset();
      setValue();
      setImage("");
      setInputValue({
        Name: "",
        LineOfBusiness: "1000",
        Telephone: "",
        FaxNo: "",
        Email: "",
        InterestedIn: "FL",
        PropertyType: "Retail",
        BrandName: "",
        Unit: "SQ.M",
        RequiredArea: "",
        Notes: "",
      });
    }
  }
  return (
    <section className="Event-form" id="Event">
      <div className="Event-CrackersAnimation">
        <Lottie animationData={CrackersAni} loop={true} />
      </div>
      <div className="Event-CrackersAnimation2">
        <Lottie animationData={CrackersAni2} loop={true} />
      </div>
      <div className="Event-WindmillAnimation">
        <Lottie
          animationData={homebgChange ? WindMillAni : WindMillAni2}
          loop={true}
        />
      </div>

      <span className="balloon1 balloon">
        <img src={Balloon4} alt="balloon" />
      </span>

      <span className="balloon2 balloon">
        <img src={Balloon2} alt="balloon" />
      </span>

      <span className="balloon3 balloon">
        <img src={Balloon3} alt="balloon" />
      </span>

      <span className="balloon4 balloon">
        <img src={Balloon4} alt="balloon" />
      </span>
      <span className="star1 star">
        <img src={Star1} alt="star" />
      </span>

      <span className="star2 star">
        <img src={Star2} alt="star" />
      </span>
      <span className="star3 star">
        <img src={Star3} alt="star" />
      </span>
      <span className="star4 star">
        <img src={Star1} alt="star" />
      </span>
      <span className="star5 star">
        <img src={Star3} alt="star" />
      </span>
      <span className="star6 star">
        <img src={Star2} alt="star" />
      </span>
      <div className="Permit-button">
        <ThemeProvider theme={theme}>
          <FormControl sx={{ width: "200px" }} color="primary">
            <InputLabel
              variant="standard"
              htmlFor="Permit"
              sx={{ color: "#a020f0" }}
            >
              Permit
            </InputLabel>
            <Select
              labelId="Permit-label"
              id="permit"
              value={permit}
              variant="standard"
              label="Age"
              onChange={handlePermitChange}
              sx={{
                color: "var(--color2)",
                "&:before": {
                  borderBottom: "1px solid #a020f0",
                },
                "&:after": {
                  borderBottom: "2px solid #a020f0",
                },
                "& .MuiSelect-icon": {
                  color: "#a020f0",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#a020f0",
                    "& .MuiMenuItem-root": {
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#8b008b",
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="Event">Event Permit</MenuItem>
              <MenuItem value="Media">Media Permit</MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
      </div>
      <div className="container">
        <form onSubmit={formSubmitHandler}>
          <div className="Eventform-name">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              name="Name"
              value={inputValue.Name}
              className="Eventform-input form-control"
              id="name"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-line-of-Business">
            <label htmlFor="lineOfBusiness" className="form-label">
              Line of Business
            </label>
            <div className="input-group">
              <input
                type="text"
                name="LineOfBusiness"
                value={inputValue.LineOfBusiness}
                className="Eventform-input form-control"
                id="lineOfBusiness"
                placeholder=""
                onChange={inputValueHandler}
                onClick={showDropdownHandler}
                onBlur={showDropdownBlurHandler}
                aria-label="LineOfBusiness"
                aria-describedby="LineOfBusiness-span"
              />
              <span className="input-group-text" id="LineOfBusiness-span">
                <IoMdArrowDropright className="line-of-business-arrow" />
              </span>
            </div>
            {showDropdown && (
              <div className="drop-down">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Business</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businesses &&
                      businesses
                        .filter((business) => {
                          const caseSensitive = true;

                          const inputValueTrimmed = (
                            inputValue?.LineOfBusiness || ""
                          ).trim();

                          if (inputValueTrimmed === "") {
                            return business;
                          }

                          if (caseSensitive) {
                            const upperInput = inputValueTrimmed.toUpperCase();
                            return (
                              business.Description.toUpperCase().includes(
                                upperInput
                              ) ||
                              business.LineOfBusiness.toUpperCase().includes(
                                upperInput
                              )
                            );
                          } else {
                            const lowerInput = inputValueTrimmed.toLowerCase();
                            return (
                              business.Description.toLowerCase().includes(
                                lowerInput
                              ) ||
                              business.LineOfBusiness.toLowerCase().includes(
                                lowerInput
                              )
                            );
                          }
                        })
                        .map((business, index) => {
                          return (
                            <tr
                              onClick={() =>
                                addValueToInput1(business.LineOfBusiness)
                              }
                              key={business["@odata.etag"] || index}
                            >
                              <td>{business.LineOfBusiness}</td>
                              <td>{business.Description}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="Eventform-line-of-Business">
            <label htmlFor="telephone" className="form-label">
              Telephone
            </label>
            <input
              type="number"
              name="Telephone"
              value={inputValue.Telephone}
              className="Eventform-input form-control"
              id="telephone"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-line-of-Business">
            <label htmlFor="faxNo" className="form-label">
              Fax No.
            </label>
            <input
              type="number"
              name="FaxNo"
              value={inputValue.FaxNo}
              className="Eventform-input form-control"
              id="faxNo"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-phone-number">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <div className="Eventform-input-phone-div">
              <input
                type="number"
                name="PhoneNumber"
                value={inputValue.PhoneNumber}
                className="Eventform-input form-control"
                id="phoneNumber"
                placeholder=""
                onChange={inputValueHandler}
                onClick={showDropdownRemoveHandler}
              />
            </div>
          </div>
          <div className="Eventform-gmail">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              name="Email"
              value={inputValue.Email}
              className="Eventform-input form-control"
              id="email"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-selected">
            <label htmlFor="interestedIn" className="form-label">
              Interested In
            </label>
            <select
              className="form-select form-input"
              name="InterestedIn"
              id="interestedIn"
              value={inputValue.InterestedIn}
              aria-label="Default select example"
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            >
              {data &&
                data.map((dat, index) => {
                  return (
                    <option
                      value={dat.SuperPropertyId}
                      key={dat["@odata.etag"] || index}
                    >
                      {dat.SuperPropertyId}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="Eventform-prperty-type">
            <label htmlFor="propertyType" className="form-label">
              Property type
            </label>
            <select
              type="text"
              name="PropertyType"
              value={inputValue.PropertyType}
              className="Eventform-input form-select"
              id="propertyType"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            >
              <option value="Retail">Retail</option>
              <option value="Wholesale">Wholesale</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="Eventform-required-area">
            <label htmlFor="RequiredArea" className="form-label">
              Required Area
            </label>
            <input
              type="number"
              name="RequiredArea"
              value={inputValue.RequiredArea}
              className="Eventform-input form-control"
              id="RequiredArea"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-Unit">
            <label htmlFor="Unit" className="form-label">
              Unit
            </label>
            <div className="input-group">
              <input
                type="text"
                name="Unit"
                value={inputValue.Unit}
                className="Eventform-input form-control"
                id="Unit"
                placeholder=""
                onChange={inputValueHandler}
                onClick={showDropdownHandler2}
                onBlur={showDropdownBlurHandler2}
                aria-label="Unit"
                aria-describedby="Unit-span"
              />
              <span className="input-group-text" id="Unit-span">
                <IoMdArrowDropright className="Unit-arrow" />
              </span>
            </div>

            {showDropdown2 && (
              <div className="drop-down">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unit &&
                      unit
                        .filter((uni) => {
                          const caseSensitive = true;

                          const inputValueTrimmed = (
                            inputValue?.Unit || ""
                          ).trim();

                          if (inputValueTrimmed === "") {
                            return uni;
                          }

                          if (caseSensitive) {
                            const upperInput = inputValueTrimmed.toUpperCase();
                            return (
                              uni.UnitSymbol.toUpperCase().includes(
                                upperInput
                              ) ||
                              uni.UnitDescription.toUpperCase().includes(
                                upperInput
                              )
                            );
                          } else {
                            const lowerInput = inputValueTrimmed.toLowerCase();
                            return (
                              uni.UnitSymbol.toLowerCase().includes(
                                lowerInput
                              ) ||
                              uni.UnitDescription.toLowerCase().includes(
                                lowerInput
                              )
                            );
                          }
                        })
                        .map((uni, index) => {
                          return (
                            <tr
                              onClick={() => addValueToInput2(uni.UnitSymbol)}
                              key={uni["@odata.etag"] || index}
                            >
                              <td>{uni.UnitSymbol}</td>
                              <td>{uni.UnitDescription}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="Eventform-Permit-date">
            <label htmlFor="Permitdate" className="form-label">
              Permit Date
            </label>
            <input
              type="datetime-local"
              name="PermitDate"
              value={inputValue.PermitDate}
              className="Eventform-input form-control"
              id="Permitdate"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-brand">
            <label htmlFor="brand" className="form-label">
              Brand Name
            </label>
            <input
              type="text"
              name="BrandName"
              value={inputValue.BrandName}
              className="Eventform-input form-control"
              id="brand"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-Special-request">
            <label htmlFor="Specialrequest" className="form-label">
              {specialRequestLabel}
            </label>
            <input
              type="text"
              name="SpecialRequest"
              value={inputValue.SpecialRequest}
              className="Eventform-input form-control"
              id="Specialrequest"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="Eventform-Civil-id">
            <label htmlFor="CivilId" className="form-label">
              Civil Id Number
            </label>
            <input
              type="number"
              name="CivilId"
              value={inputValue.CivilId}
              className="Eventform-input form-control"
              id="CivilId"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            />
          </div>
          <div className="form-floating Eventform-notes">
            <textarea
              className="form-input form-control"
              name="Notes"
              placeholder="Leave a comment here"
              id="Notes"
              value={inputValue.Notes}
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            ></textarea>
            <label htmlFor="Notes">Notes</label>
          </div>
          <div className="Eventform-button-group">
            <div>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{
                  backgroundColor: "#a020f0",
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
                }}
                size="large"
                className="Eventform-upload-button"
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              {files &&
                files.map((file, index) => {
                  const baseFileName = file.fileName
                    .split(".")
                    .slice(0, -1)
                    .join(".");
                  return (
                    <div key={index} className="upload-files-show-name">
                      <p>
                        <IoStar className="upload-files-show-star" />
                        {baseFileName}
                        <MdCancel
                          className="upload-files-cancel"
                          onClick={() => removePhotoHandler(index)}
                        />
                      </p>
                    </div>
                  );
                })}
            </div>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#a020f0",
                boxShadow:
                  "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
              }}
              size="large"
              className="Eventform-submit-button"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
