import React, { useState, useRef } from "react";
import Nav from "./Nav";
import "./Header.css";
import { Sling as Hamburger } from "hamburger-react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/header/logoSeilax.png";

export default function Header({ homebgChange, setHomebgChange }) {
  const navRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [header, setHeader] = useState(true);
  const navigate = useNavigate();
  function hamburgerHandler() {
    navRef.current.classList.toggle("nav-show");
  }

  function signInHandler() {
    navigate("/Login");
  }
  function hamburgerBlurHandler() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
    });
  }
  function headerScroll() {
    if (window.scrollY >= 100) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }

  window.addEventListener("scroll", headerScroll);
  function modeHandler() {
    document.body.classList.toggle("dark-mode");
    setHomebgChange(!homebgChange);
  }
  return (
    <header className={header ? "header-unscroll" : "sticky-top header-scroll"}>
      <div className="row header-row">
        <div className="col-3 col-sm-7 header-col1">
          <div className="header-col1-subdiv">
            <img src={Logo} alt="logo" />
          </div>
        </div>
        <div className="col-1 col-sm-2 header-col2">
          <div ref={navRef} className="nav-hide">
            <Nav isOpen={isOpen} setOpen={setOpen} navRef={navRef} />
          </div>
        </div>
        <div className="col-8 col-sm-3  header-col3">
          <div
            onClick={hamburgerHandler}
            onBlur={hamburgerBlurHandler}
            className="hamburger"
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <div className="mode-class">
            <input type="checkbox" name="mode" id="check" />
            <label
              htmlFor="check"
              className="rounded-pill mode-label"
              onClick={modeHandler}
            ></label>
          </div>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#a020f0",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
              borderRadius: "100px",
              height: "28px",
              width: "50px",
              fontSize: "12px",
              textTransform: "none",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
            }}
            size="large"
            className="header-sign-in-button"
            onClick={signInHandler}
          >
            SignIn
          </Button>
        </div>
      </div>
    </header>
  );
}
