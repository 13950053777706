import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ScrollTop from "./components/ScrollTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import FitOutProcess from "./pages/FitOutProcess";
import Maintenance from "./pages/Maintenance";
import Event from "./pages/Event";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem("isLoggedIn") === "true");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login onLogin={handleLogin} />} />

        <Route
          path="/Fit"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <FitOutProcess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Maintenance"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Maintenance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Event"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Event />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ScrollTop />
      <ToastContainer />
    </div>
  );
}

export default App;
