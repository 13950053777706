import React, { useState } from "react";
import "./EventNav.css";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const navVariants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const navChildVariants = {
  hidden: {
    y: -100,
    scale: 1.5,
  },
  visible: {
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 120,
      duration: 5,
    },
  },
};

export default function EventNav({ isOpen, setOpen, navRef }) {
  const [navScroll, setNavScroll] = useState(true);
  const [navLinkScroll, setNavLinScroll] = useState(true);
  const navigate = useNavigate();

  function navLinkScrollHandler() {
    if (window.scrollY >= 100) {
      setNavLinScroll(false);
    } else {
      setNavLinScroll(true);
    }
  }

  window.addEventListener("scroll", navLinkScrollHandler);
  function navScrollHandler() {
    if (window.scrollY >= 50) {
      setNavScroll(false);
    } else {
      setNavScroll(true);
    }
  }

  window.addEventListener("scroll", navScrollHandler);
  function navLinkHandler1() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
      navigate("/Fit");
    });
  }
  function navLinkHandler2() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
    });
  }
  return (
    <motion.nav
      variants={navVariants}
      initial="hidden"
      animate="visible"
      className={!navScroll ? "Eventnav-scroll" : undefined}
    >
      <motion.div variants={navChildVariants} className="Eventnav-link-div">
        <Link
          onClick={navLinkHandler1}
          // to="Event-Home"
          // spy={true}
          // smooth={true}
          // offset={-100}
          // duration={500}
          className={navLinkScroll ? "Eventnav-link" : "Eventnav-link-scroll"}
        >
          Home
        </Link>
      </motion.div>
      <motion.div variants={navChildVariants}>
        <Link
          onClick={navLinkHandler2}
          to="Event"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={navLinkScroll ? "Eventnav-link" : "Eventnav-link-scroll"}
        >
          Permit
        </Link>
      </motion.div>
    </motion.nav>
  );
}
