import React, { useState, useRef } from "react";
import MaintenanceNav from "./MaintenanceNav";
import "./MaintenanceHeader.css";
import { Sling as Hamburger } from "hamburger-react";
import Logo from "../Assets/header/logoSeilax.png";

export default function MaintenanceHeader({ homebgChange, setHomebgChange }) {
  const navRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [header, setHeader] = useState(true);
  function hamburgerHandler() {
    navRef.current.classList.toggle("nav-show");
  }
  function hamburgerBlurHandler() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
    });
  }
  function headerScroll() {
    if (window.scrollY >= 100) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }

  window.addEventListener("scroll", headerScroll);
  function modeHandler() {
    document.body.classList.toggle("dark-mode");
    setHomebgChange(!homebgChange);
  }
  return (
    <header
      className={
        header
          ? "Maintenance-header-unscroll"
          : "sticky-top Maintenance-header-scroll"
      }
    >
      <div className="row Maintenance-header-row">
        <div className="col-6 col-sm-4  Maintenance-header-col1">
          <div className="Maintenance-header-col1-subdiv">
            <img src={Logo} alt="Fit-logo" />
          </div>
        </div>
        <div className="col-1 col-sm-6  Maintenance-header-col2">
          <div ref={navRef} className="Maintenance-nav-hide">
            <MaintenanceNav isOpen={isOpen} setOpen={setOpen} navRef={navRef} />
          </div>
        </div>
        <div className="col-5 col-sm-2   Maintenance-header-col3">
          <div
            onClick={hamburgerHandler}
            onBlur={hamburgerBlurHandler}
            className="Maintenance-hamburger"
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <div className="Maintenance-mode-class">
            <input type="checkbox" name="mode" id="check" />
            <label
              htmlFor="check"
              className="rounded-pill mode-label"
              onClick={modeHandler}
            ></label>
          </div>
        </div>
      </div>
    </header>
  );
}
