import React, { useState } from "react";
import "./FitNav.css";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const navVariants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const navChildVariants = {
  hidden: {
    y: -100,
    scale: 1.5,
  },
  visible: {
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 120,
      duration: 5,
    },
  },
};

export default function FitNav({ isOpen, setOpen, navRef }) {
  const [navScroll, setNavScroll] = useState(true);
  const [navLinkScroll, setNavLinScroll] = useState(true);

  function navLinkScrollHandler() {
    if (window.scrollY >= 100) {
      setNavLinScroll(false);
    } else {
      setNavLinScroll(true);
    }
  }

  window.addEventListener("scroll", navLinkScrollHandler);

  function navScrollHandler() {
    if (window.scrollY >= 50) {
      setNavScroll(false);
    } else {
      setNavScroll(true);
    }
  }

  window.addEventListener("scroll", navScrollHandler);
  function navLinkHandler() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
    });
  }

  return (
    <motion.nav
      variants={navVariants}
      initial="hidden"
      animate="visible"
      className={!navScroll ? "Fit-nav-scroll" : "Fit-nav"}
    >
      <motion.div variants={navChildVariants} className="Fit-nav-link-div">
        <Link
          onClick={navLinkHandler}
          to="Fit-Home"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={navLinkScroll ? "Fit-nav-link" : "Fit-nav-link-scroll"}
        >
          Home
        </Link>
      </motion.div>
      <motion.div variants={navChildVariants}>
        <Link
          onClick={navLinkHandler}
          to="FitOut"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={navLinkScroll ? "Fit-nav-link" : "Fit-nav-link-scroll"}
        >
          Fit Out
        </Link>
      </motion.div>
      <motion.div variants={navChildVariants}>
        <NavLink
          to="/Maintenance"
          className={navLinkScroll ? "Fit-nav-link" : "Fit-nav-link-scroll"}
        >
          Maintenance
        </NavLink>
      </motion.div>
      <motion.div variants={navChildVariants}>
        <NavLink
          to="/Event"
          className={navLinkScroll ? "Fit-nav-link" : "Fit-nav-link-scroll"}
        >
          Permit
        </NavLink>
      </motion.div>
      {/* <motion.div className="properties" variants={navChildVariants}>
        <Link to="" className="nav-link  nav-link-2">
          Properties
          <RiArrowDropRightLine className="nav-link-arrow" />
        </Link>

        <ul className="dropdownmenu-properties list-inline">
          <li>Assima Mall</li>
        </ul>
      </motion.div>
      <motion.div className="eoi" variants={navChildVariants}>
        <Link to="" className="nav-link  nav-link-3">
          Eoi
          <RiArrowDropRightLine className="nav-link-arrow" />
        </Link>
        <ul className="dropdownmenu-eoi list-inline">
          <li>Prospect</li>
        </ul>
      </motion.div>
      <motion.div className="fitout" variants={navChildVariants}>
        <Link to="" className="nav-link">
          Fit out
          <RiArrowDropRightLine className="nav-link-arrow" />
        </Link>
        <ul className="dropdownmenu-fitout list-inline">
          <li>Request</li>
          <li>Documents</li>
        </ul>
      </motion.div>
      <motion.div className="project" variants={navChildVariants}>
        <Link to="" className="nav-link">
          The Project
          <RiArrowDropRightLine className="nav-link-arrow" />
        </Link>
        <ul className="dropdownmenu-project list-inline">
          <li>Request</li>
          <li>Documents</li>
        </ul>
      </motion.div>
      <motion.div className="search" variants={navChildVariants}>
        <Link to="" className="nav-link">
          Search
          <RiArrowDropRightLine className="nav-link-arrow" />
        </Link>
        <div className="input-group dropdownmenu-search">
          <input type="serach" name="search" />
          <span className="input-group-text  fs-1">
            <IoSearchSharp />
          </span>
        </div>
      </motion.div> */}
    </motion.nav>
  );
}
