import React, { useState, useRef } from "react";
import "./EventHeader.css";
import { Sling as Hamburger } from "hamburger-react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/header/logoSeilax.png";
import EventNav from "./EventNav";

export default function EventHeader({ homebgChange, setHomebgChange }) {
  const navRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [header, setHeader] = useState(true);
  const navigate = useNavigate();
  function hamburgerHandler() {
    navRef.current.classList.toggle("nav-show");
  }

  function signInHandler() {
    navigate("/Login");
  }
  function hamburgerBlurHandler() {
    setTimeout(() => {
      setOpen(false);
      navRef.current.classList.remove("nav-show");
    });
  }
  function headerScroll() {
    if (window.scrollY >= 100) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }

  window.addEventListener("scroll", headerScroll);
  function modeHandler() {
    document.body.classList.toggle("dark-mode");
    setHomebgChange(!homebgChange);
  }
  return (
    <header
      className={
        header ? "Eventheader-unscroll" : "sticky-top Eventheader-scroll"
      }
    >
      <div className="row Eventheader-row">
        <div className="col-6 col-sm-2 Eventheader-col1">
          <div className="Eventheader-col1-subdiv">
            <img src={Logo} alt="Eventlogo" />
          </div>
        </div>
        <div className="col-1 col-sm-8 Eventheader-col2">
          <div ref={navRef} className="Eventnav-hide">
            <EventNav isOpen={isOpen} setOpen={setOpen} navRef={navRef} />
          </div>
        </div>
        <div className="col-5 col-sm-2  Eventheader-col3">
          <div
            onClick={hamburgerHandler}
            onBlur={hamburgerBlurHandler}
            className="Eventhamburger"
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <div className="Eventmode-class">
            <input type="checkbox" name="mode" id="check" />
            <label
              htmlFor="check"
              className="rounded-pill Eventmode-label"
              onClick={modeHandler}
            ></label>
          </div>
        </div>
      </div>
    </header>
  );
}
